.home {
  background-color: $light;

  .container {
    height: 100vh; // Fallback for browsers not supporting svh
    height: 100svh;
  }

  h1 {
    // calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
    font-size: calc(4rem + (8 - 4) * ((100vw - 15rem) / (100 - 15)));
    line-height: calc(6rem + (10 - 6) * ((100vw - 15rem) / (100 - 15)));

    transform: rotateZ(-90deg);
    transform-origin: 40% -20%;
    transition: 1s ease;
    margin-left: -12rem;

    @media (min-width: 450px) {
      margin: 2rem 2rem 4rem 0;
      transform: rotateZ(0);
    }
  }

  &__contact {
    display: flex;
    margin: 3rem auto;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    align-items: center;

    @media (min-width: 450px) {
      margin: 3rem 0;
      justify-content: flex-start;
    }
    a {
      color: $black;
      @media (min-width: 570px) {
      }

      &:hover svg {
        filter: drop-shadow(3px 3px 1px rgba(104, 104, 104, 0.3));
      }
    }
  }

  &__card,
  &__github,
  &__linkedin {
    height: 30px;
    @media (min-width: 450px) {
      height: 60px;
    }
  }

  &__mail {
    height: 35px;
    @media (min-width: 450px) {
      height: 68px;
    }
  }
}
