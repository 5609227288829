.education {
  background-color: $dark;
  min-height: 100vh;
}

.education__main {
  align-items: top;
  display: flex;
  flex-direction: column;

  @media (min-width: 900px) {
    flex-direction: row;
  }
}

.education__timeline {
  margin: 0;
  position: relative;

  @media (min-width: 900px) {
    margin: 0 10rem;
  }
}

.education__timeline::after {
  //line timeline
  background-color: $black;
  bottom: 0;
  content: "";
  position: absolute;
  width: 0.3rem;
  top: 2rem;
  left: 0;
  animation: edu-timeline 5s ease;
}

@keyframes edu-timeline {
  0% {
    bottom: 99%;
  }
  100% {
    bottom: 0;
  }
}

.education__item {
  position: relative;
  margin: 0 1rem 4rem 4rem;

  @media (min-width: 900px) {
    margin: 0 1rem 4rem 7rem;
  }

  h2 {
    margin: 0;
    line-height: 28px;
  }

  h3 {
    margin: 0;
    span {
      font-size: 12px;
    }
  }
}

.education__details {
  color: $dark-gray;
  font-size: 14px;
  margin-bottom: 5px;
}

.education__list .education__details {
  margin-top: 0;
  margin-bottom: 10px;
}

.education__text {
  font-size: 16px;
  margin-top: 5px;
}

.education__item::after {
  //circle timeline
  background-color: $black;
  border-radius: 50%;
  content: "";
  height: 1.7rem;
  left: -4.7rem;
  position: absolute;
  top: 0;
  width: 1.7rem;

  @media (min-width: 900px) {
    left: -7.7rem;
  }
}
