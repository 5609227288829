.language{
    background-color: $middle;
    height: 100vh; // Fallback for browsers not supporting svh
    height: 100svh;
}

.language__main{
    display: flex;
    flex-direction: column;

    h1{
        margin-bottom:1rem;
    }
}

.language__content{
    display: grid;
    gap: 4rem;
   grid-template-columns: 1fr 1fr;
   margin-bottom: 5rem;

   @media(min-width: 800px){
    grid-template-columns: 1fr 1fr 1fr 1fr;

}

}

