.computer {
  background-color: $light;
  min-height: 100vh;
}

.computer__main {
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 800px) {
    flex-direction: row;
  }
}

.computer__content {
  flex-grow: 1;
  margin-left: 0;
  display: grid;
  grid-template-columns: repeat(1, minmax(15rem, 1fr));
  gap: 2rem;

  @media (min-width: 570px) {
    grid-template-columns: repeat(3, minmax(15rem, 1fr));
  }

  @media (min-width: 800px) {
    margin-left: 7rem;
  }

  .computer__skill {
    min-width: 100%;
    border: 1px solid $black;
    padding: 1.5rem;
    transition: 0.2s;
    height: auto;

    i {
      display: inline-block;
      margin-bottom: 0.5rem;
    }
  }
}

.computer__skill__info {
  display: block;
  transition: 0.2s;
  font-size: 1.4rem;
}
