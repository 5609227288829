.header {
  box-shadow: 0 0 0.5rem $black;
  position: absolute;
  width: 100%;
  z-index: 1;
  font-size: 1.7rem;
}

.header__nav {
  align-content: stretch;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 1000px) {
    display: flex;
    flex-direction: row;
  }
}

.header__nav--active {
  display: flex;
  background-color: $black;
  color: $ultra-light;
}

.header__item {
  padding: 1rem;
  width: 100%;
  text-align: center;
  border: 0.5px solid $black;
  color: $light;

  @media (min-width: 1000px) {
    color: $black;
  }

  &:hover {
    text-decoration: none;
    background-color: $dark-gray;
    color: white;

  }
}

.header__icon {
  text-align: center;
  font-size: 20px;
  padding: 10px;

  @media (min-width: 1000px) {
    display: none;
  }
  &:hover {
    cursor: pointer;
  }
}
.header__icon--hide {
  display: none;
}

#close-header {
  margin-right: 2rem;
  width: 100%;
  background-color: $black;
  color: $light;

  &:hover {
    color: $middle;
  }
}

.is-active {
  background-color: $ultra-light;
  color: $black;

  @media (min-width: 1000px) {
    color: $ultra-light;
    background-color: $black;
  }
}
