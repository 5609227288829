//colors:
$light: #f1dfd7;
$dark: #cd9b6f;
$middle: #f4b78d;
$ultra-light: #fff5f3;
$black: #201f1f;
$dark-gray: #3b3b3b;
$link: #d25d1d;

//Font size:
$fs-ultra-large: 6rem;
$fs-large: 3rem;
$fs-small: 1.4rem;
//Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;
